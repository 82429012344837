<template>
    <h1>{{ title }}</h1>
    <slot/>
</template>

<script>
export default {
    name: "Error",
    props: {
        title: {
            type: String,
            default: 'Ошибка',
        }
    }
}
</script>

<style scoped>

</style>
