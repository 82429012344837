<template>
    <div class="form">
        <loading-indicator v-if="loading"/>
        <error v-else-if="error !== null" v-bind:title="payment ? 'Не удалось перейти к оплате' : 'Не удалось получить данные платежа'">
            <p>{{ error }}</p>
            <small>Идентификатор платежа: <code>{{ this.$route.params.id }}</code></small>
        </error>
        <payment-form v-else v-bind:project="project" v-bind:payment="payment" v-bind:methods="methods" @submit="pay"/>
    </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import axios from "axios";
import * as api from '@/api/core'
import Error from "@/components/Error";
import PaymentForm from "@/components/PaymentForm";

export default {
    name: "Pay",
    components: { error: Error, LoadingIndicator, PaymentForm },
    data: function () {
        return {
            loading: true,
            payment: null,
            project: null,
            methods: null,
            error: null,
        }
    },
    async mounted() {
        try {
            this.payment = (await axios.get(api.resource(`payments/${ this.$route.params.id }`))).data.response
            this.project = (await axios.get(api.resource(`payments/${ this.$route.params.id }/project`))).data.response
            this.methods = (await axios.get(api.resource(`payments/${ this.$route.params.id }/methods`))).data.response

            if (this.payment.name) document.title = `${ this.payment.name } - ${ this.project.name }`
        } catch(error) {
            if (error.response) {
                this.error = error.response.data.error
            } else this.error = error
        } finally {
            this.loading = false
        }
    },
    methods: {
        pay: async function (providerName, methodName) {
            this.loading = true
            try {
                let response = (await axios.put(api.resource(`payments/${ this.payment.uuid }/pay`), {
                    provider_name: providerName,
                    payment_method: methodName,
                })).data.response

                window.location.href = response.pay_url
            } catch(error) {
                if (error.response) {
                    this.error = error.response.data.error
                } else this.error = error
            } finally {
                this.loading = !this.error
            }
        }
    }
}
</script>

<style>
.form {
    margin: 32px 0;
}
</style>
