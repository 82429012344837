<template>
    <a v-on:click="$emit('submit', providerName, method.name)" v-bind:class="this.disabled ? 'disabled' : ''">
        <div class="icon-wrapper">
            <payment-method-icon v-bind:name="method.icon" variant="method"/>
        </div>
        <div class="main-wrapper">
            <h4>{{ method.display_name }}</h4>
            <p v-if="method.description !== null">{{ method.description }}</p>
        </div>
        <div class="commission-wrapper">
            <p class="commission">
                <slot v-if="payment.sum > method.max_sum">
                    <small>Макс.</small><br>
                    {{ Number(method.max_sum).toFixed() }} ₽
                </slot>
                <slot v-else-if="payment.sum < method.min_sum">
                    <small>Мин.</small><br>
                    {{ Number(method.min_sum).toFixed() }} ₽
                </slot>
                <slot v-else>
                    <small>Комиссия</small><br>
                    {{ Number(method.commission * 100).toFixed(0) }}%
                </slot>
            </p>
        </div>
    </a>
</template>

<script>
import PaymentMethodIcon from "@/components/PaymentMethod";

export default {
    name: "PaymentOption",
    components: { PaymentMethodIcon },
    props: {
        method: {
            type: Object,
            required: true,
        },
        providerName: {
            type: String,
            required: true,
        },
        payment: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped lang="sass">
@import "../style/variables"

a
    margin-top: 16px
    margin-bottom: 16px
    display: grid
    background-color: $optimized-glassmorphism-background-color
    border-radius: $main-border-radius
    transition: $default-release-transition

    @media screen and (max-width: $small-screen-size)
        display: block
        text-align: center

        h4
            font-size: 1.4em

    @media screen and (min-width: $small-screen-size)
        grid-template-columns: 72px 4fr 1fr

    &:not(.disabled)
        cursor: pointer

        &:hover
            background-color: $glassmorphism-hover-background-color
            transform: $default-transition

    &.disabled
        cursor: not-allowed

.icon-wrapper, .main-wrapper, .commission-wrapper
    padding: 24px
    position: relative

    @media screen and (max-width: $small-screen-size)
        padding: 12px

.icon-wrapper, .commission-wrapper
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap

    img
        margin: auto

.icon-wrapper
    color: white

    @media screen and (min-width: $small-screen-size)
        padding-right: 0

    @media screen and (max-width: $small-screen-size)
        padding-top: 24px

.commission-wrapper
    text-align: center
    padding-left: 0

    p
        margin: 0
        font-weight: 600

    small
        font-weight: 400

    @media screen and (min-width: $small-screen-size)
        margin-right: 0

    @media screen and (max-width: $small-screen-size)
        padding-bottom: 24px

h4
    margin: 0

p
    margin-top: 8px
    margin-bottom: 0
    font-size: 16px
</style>
