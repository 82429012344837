<template>
    <slot v-if="payment.mode !== 0">
        <warning>
            <b>Внимание!</b> Этот платёж проводится в тестовом режиме и даже фактическая оплата будет обработана с ограничениями.
            Если Вы считаете, что это ошибка, обратитесь в техническую поддержку.
        </warning>
        <br>
    </slot>
    <div class="wrapper">
        <div class="info">
            <div>
                <h1>{{ project.name }}</h1>
                <p>{{ payment.description }}</p>
                <total v-bind:sum="payment.sum.toFixed(2)"/>
            </div>
            <div>
                <div class="advanced">
                    <h4>Принимаем платежи через</h4>
                    <payment-providers v-bind:size="32"/>
                </div>
                <div class="advanced">
                    <h4>Способы оплаты</h4>
                    <payment-methods variant="start"/>
                </div>
            </div>
        </div>
        <div class="delimiter"></div>
        <div class="form">
            <p v-if="availableMethods.length > 0">Выберите способ оплаты:</p>
            <payment-option
                v-for="method in availableMethods"
                v-bind:provider-name="method.provider"
                v-bind:method="method"
                v-bind:key="method.name"
                v-bind:payment="payment"
                @submit="(p, m) => $emit('submit', p, m)"
            />
            <p v-if="unavailableMethods.length > 0">Недоступные способы оплаты:</p>
            <payment-option
                v-for="method in unavailableMethods"
                v-bind:provider-name="method.provider"
                v-bind:method="method"
                v-bind:key="method.name"
                v-bind:payment="payment"
                disabled
            />
        </div>
    </div>
</template>

<script>
import PaymentOption from "@/components/PaymentOption";
import PaymentProviders from "@/components/PaymentProviders";
import PaymentMethods from "@/components/PaymentMethods";
import Total from "@/components/Total";
import Warning from "@/components/Tip";

function isAvailableMethod(payment, method) {
    return payment.sum >= method.min_sum && payment.sum <= method.max_sum;
}

export default {
    name: "PaymentForm",
    components: { Total, PaymentMethods, PaymentProviders, PaymentOption, Warning },
    computed: {
        sortedMethods() {
            let result = []

            for (const provider of Object.keys(this.methods)) {
                const methods = this.methods[provider]
                for (const method of methods) {
                    result.push({ provider, ...method })
                }
            }

            return result.sort((a, b) => a.order - b.order)
        },
        availableMethods() {
            return this.sortedMethods.filter((method) => isAvailableMethod(this.payment, method))
        },
        unavailableMethods() {
            return this.sortedMethods.filter((method) => !isAvailableMethod(this.payment, method))
        }
    },
    props: {
        payment: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
        methods: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped lang="sass">
@import "../style/variables"

.wrapper
    @media screen and (min-width: $small-screen-size)
        display: grid
        grid-template-columns: 3fr 10px 5fr

.delimiter
    height: 100%
    border: 1px solid darken(white, 50%)
    width: 0

    @media screen and (max-width: $small-screen-size)
        display: none

.info, .form
    @media screen and (min-width: $small-screen-size)
        padding: 16px 64px

.info
    display: flex
    flex-direction: column
    justify-content: space-between

    @media screen and (min-width: $small-screen-size)
        min-height: 512px

    @media screen and (max-width: $small-screen-size)
        .advanced
            display: none

.form
    @media screen and (max-width: $small-screen-size)
        margin-top: 64px
</style>
