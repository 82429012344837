<template>
    <div class="total">
        <span class="sum">
            {{ sum }}
        </span>
        &nbsp;
        <span class="sign">
            ₽
        </span>
    </div>
</template>

<script>
export default {
    name: "Total",
    props: {
        sum: {
            type: Number,
            required: true,
        }
    }
}
</script>

<style scoped lang="sass">
.total
    display: flex
    font-size: 36px

.sum
    font-weight: 600
</style>
