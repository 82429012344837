<template>
    <div class="tip">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Warning",
}
</script>

<style scoped lang="sass">
@import "../style/variables"

.tip
    background-color: $warning-background-color
    border: 2px solid $warning-color
    border-radius: $main-border-radius
    padding: 16px 24px
</style>
